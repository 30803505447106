<template>
  <div class="container">
    <h1>Frequently Asked Questions</h1>
    <div class="questions">
        <p><span class="question">Are there tests?</span>
        No. This is for fun.</p>

        <p><span class="question">What if I miss a day?</span>
        Respect your fellow listeners, and all will be fine!
        We will summarize, especially if you’re a few minutes late & we’ve already begun.
        Or schedule a make-up time, to hear what you missed.
        Or read it yourself and rejoin us.</p>

        <p><span class="question">Can I copy what you’re doing?</span>
        YES!!! Go for it! That’s the spirit!</p>

        <p><span class="question">Are there rules?</span>
        Yes. Be kind.</p>

        <p><span class="question">If your audio takes a long time to connect…</span>
        If your internet is cranky, it often takes a while for you to connect. 
        I may suggest you turn off video.
        Use chat if voice is distorted.
        We often stop and wait if you drop out or walk away briefly.
        If you are leaving & want to tell us something as you go, use the chat.</p>

        <p><span class="question">Are recordings allowed?</span>
        No… respecting copyright and authors and publishers is vital.
        When the pandemic ends, we will cease if publishers wish it.</p>

        <p><span class="question">What sort of document camera do you use?</span>
        IPEVO VZ-R. Readers early on loved seeing the art, which is often stunningly beautiful.
        If you’re copying this idea, use a good document camera to honor the art.</p>

        <p><span class="question">What if I cannot see the screen share?</span>
        Say something. Usually if I restart the share (which is easy) that fixes the problem.</p>

        <p><span class="question">Can friends listen?</span>
        Yes. That’s the whole idea! Your family can listen too… it might improve their day. Best of all, copy this idea and read together.</p>

        <p><span class="question">Do you read with silly voices?</span>
        Sometimes. Especially if it’s the pigeon. Or Goldilocks in one favorite book.</p>

        <p><span class="question">Where do you get your books?</span>
        Staying home, electronic books via public libraries are vital. Books I love I buy to reread. Ordering I use independent bookstores. <a href="http://www.bookshop.org" target="_blank">Bookshop.org</a> is a great way to find them. <a href="http://www.hicklebees.com" target="_blank">Hicklebee’s</a> of San Jose, CA has been a source of knowledge and community for many years. If we need a book that’s hard to find, I will find it… that’s a librarian superpower. If your budget is small, use libraries. They are a delightful public good, which is why they are free in the US.</p>

        <p><span class="question">But… Screen time!!</span>
        Picture books require screens. Novels don’t. Listeners have been seen drawing, swimming, swinging, trampolining, riding in cars, inline skating inside, and having lunch.</p>

        <p><span class="question">Why?</span>
        Depth of connection. The pandemic forced us to reinvent library. Sharing books is powerful, and this is a superb new way to share! I can use my finding skills for readers. When students choose to listen for an hour, on their own time, it is worth noticing. When we track down an eBook together, we are practicing skills together. Remote is powerful in a different way than physical libraries, and suited to this era.</p>

        <p><span class="question">What time zone are you in?</span>
        California, USA is Pacific Time. That’s ~8 hours behind GMT.</p>

        <p><span class="question">Will you read to people in other time zones?</span>
        Of course.</p>

        <p><span class="question">Why did you leave your dream job?</span>
        I did not want to retire, so I'm trying to see it as relocating. Rosa Parks said “Each person must live their life as a model for others.” I am modeling staying home, while continuing to read. Also teaching readers to access books electronically. Help me build this new flavor of library. <span class="heart">&#10084;</span></p>

        <p><span class="question">Are questions allowed?</span>
        Of course!
        If you’re shy, ask in the chat.
        If I miss your question, I will read the chat later and send an answer if I have email for you.</p>

        <p><span class="question">What if I want my video off because I’m feeling shy?</span>
        If enough people request it, I can read in presentation mode. But seeing your faces and thumbs up/down for questions is helpful.</p>

        <p><span class="question">I want it to happen faster!</span>
        Find some friends and copy the idea nearby!
        Shared reads are lovely for readers too.</p>

        <p><span class="question">Do you read series in order?</span>
        Yes. Start to finish if readers stay interested. Order is helpful (you wouldn’t read the chapters of a book in random order, right?). Also helpful for catching them all.</p>

        <p><span class="question">Can I schedule a reading?</span>
        Yes. Please email the librarian.</p>

        <p><span class="question">What if I want to hear something else?</span>
        Schedule a reading. And collect friends to join you!</p>

        <p><span class="question">Will you read anything?</span>
        LOL! You can try to talk me in to whatever you wish.
        But life is short, so I read a lot of books I love.
        If you love it enough, you’ll read it even if I’d rather not.
        Chances are we can find books to agree on.</p>

        <p><span class="question">How long will this library keep going?</span>
        A long time, I hope. Longer still if it’s copied.</p>
    </div>
  </div>
</template>

<script>
export default {
    name: "FAQ"
}
</script>

<style scoped>
    .container {
        flex-direction: column;
    }

    h1 {
        text-align: center;
        font-size: 2rem;
    }

    .questions {
        margin: auto;
        max-width: 850px;
    }

    p {
        margin-top: 1.2rem;
        white-space: pre-line;
        font-size: 1.2rem;
    }

    span.question {
        font-weight: bold;
    }

    span.question::after {
        content: "\A";
    }

    span.heart {
        color: red;
    }
</style>